import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, ListItemText } from '@Components/';
import { MoreVertical as MoreVerticalIcon } from '@SvgIcon/';

const PointsProductGiftExchangeTableMoreLabel = React.memo(({ rowData, onClick: onClickProps }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleToggleClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setAnchorEl(e.currentTarget);
    };

    // 關閉menu錨點
    const handleClose = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setAnchorEl(null);
    };

    const handleOnClick = (e, type) => {
        onClickProps(type, rowData);
        handleClose(e);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleToggleClick}>
                <MoreVerticalIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={(e) => handleOnClick(e, 'editProductGift')}>
                    <ListItemText>編輯贈品</ListItemText>
                </MenuItem>
                <MenuItem onClick={(e) => handleOnClick(e, 'editProductGiftStock')}>
                    <ListItemText>庫存調整</ListItemText>
                </MenuItem>
                <MenuItem onClick={(e) => handleOnClick(e, 'editProductGiftStock')}>
                    <ListItemText>庫存調整</ListItemText>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
});

export default PointsProductGiftExchangeTableMoreLabel;
