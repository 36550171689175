import { useCallback } from 'react';
import useAxios from '@Apis/useAxios';
import { GET_ME_MENUS_API } from '@Apis/apiPath';

const useNavigationApi = () => {
    const Axios = useAxios();

    const getMeMenusApi = useCallback(
        async () => {
            const resp = await Axios.get(GET_ME_MENUS_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getMeMenusApi,
    };
};

export default useNavigationApi;
