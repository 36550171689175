import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, ListItemText } from '@Components/';
import { MoreVertical as MoreVerticalIcon } from '@SvgIcon/';

const PointsActivityTableMoreLabel = ({ rowData, onClick: onClickProps }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleToggleClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setAnchorEl(e.currentTarget);
    };

    // 關閉menu錨點
    const handleClose = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setAnchorEl(null);
    };

    const handleOnClick = (e, type) => {
        onClickProps(type, rowData);
        handleClose(e);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleToggleClick}>
                <MoreVerticalIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={(e) => handleOnClick(e, 'editPointActivity')}>
                    <ListItemText>編輯活動</ListItemText>
                </MenuItem>
                {rowData.canResetItem && <MenuItem onClick={(e) => handleOnClick(e, 'resetPointActivityItems')}>
                    <ListItemText>建置/重置兌換項目</ListItemText>
                </MenuItem>}
            </Menu>
        </React.Fragment>
    );
};

export default PointsActivityTableMoreLabel;
