import React, { useImperativeHandle, useState, useRef, useEffect } from 'react';
import { FormControl, FormLabel, FormHelperText, OutlinedInput, Stack } from '@Components/';
// import { isCorrectCardNo } from '@Util/utils';
import TextInputMask from '../textInputMask/TextInputMask';
import clsx from 'clsx';

const TextField = React.forwardRef((props, ref) => {
    const {
        autoComplete,
        autoFocus = false,
        children,
        className,
        defaultValue,
        value,
        disabled = false,
        isError: propsError = false,
        fullWidth = false,
        helperText = '此選項為必填，不得為空值',
        inputProps,
        InputProps,
        name,
        id,
        label,
        maxRows,
        minRows,
        multiline = false,
        onBlur,
        onChange,
        onFocus,
        required = false,
        rows,
        type,
        lightColor = false,
        readOnly = false,
        direction: directionProps = 'column',
        onChangeNative,
        inputRootStyle = {},
        inputStyle = {},
        maskType,
        placeholder,
        maxLength: maxLengthProps,
        ...other
    } = props;
    const [isError, setError] = useState(propsError);
    // 設定錯誤訊息
    const [errorText, setErrorText] = useState(helperText);
    const inputValueRef = useRef('');

    const defaultProps = {
        autoComplete: autoComplete,
        autoFocus: autoFocus,
        defaultValue: defaultValue,
        value: value,
        fullWidth: fullWidth,
        multiline: multiline,
        id: id,
        name: name,
        rows: rows,
        maxRows: maxRows,
        minRows: minRows,
        inputRef: inputValueRef,
        type: type,
        inputProps: { maxLength: maxLengthProps, ...inputProps },
        onChange,
        readOnly,
        onChangeNative,
        inputRootStyle,
        inputStyle,
        placeholder,
        ...InputProps,
    };

    if (['IDCARD', 'CCN', 'CVV2', 'MOB', 'CDC', 'MB', 'TID', 'DC', 'TEL','MONEY'].includes(maskType)) {
        defaultProps.inputProps = { ...defaultProps.inputProps, maskType, required: required };
        defaultProps.inputComponent = TextInputMask;
    }

    useImperativeHandle(
        ref,
        () => ({
            isError: () => {
                let source = required;
                let _helperText = helperText;
                const value = inputValueRef.current.value.valueOf();
                if (required && inputValueRef && inputValueRef.current && value.trim() !== '') {
                    source = false;
                }
                if (required && maskType && maskType === 'MOB') {
                    source = !/^09[\d]{8}/g.test(value);
                    _helperText = '手機格式或長度有誤，請輸入正確格式';
                }
                if (required && maskType && maskType === 'CCN') {
                    source = !/^[\d*]{16}$/g.test(value);
                    _helperText = '信用卡格式錯誤，請輸入16碼數字';
                }
                if (required && type && type === 'email') {
                    if (!!value) {
                        source = !/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/.test(value);
                        _helperText = '信箱格式錯誤，請輸入正確格式';
                    } else {
                        source = false;
                    }
                }
                setErrorText(_helperText);
                setError(source);
                return source;
            },
            setError: (chk, text = helperText) => {
                setErrorText(text);
                setError(!!chk);
            },
            getResult: () => {
                let value = (inputValueRef.current && inputValueRef.current.value) || '';
                if (value) value = value.trim();
                return value;
            },
            getName: () => inputValueRef.current.name,
            getId: () => inputValueRef.current.id,
            node: () => inputValueRef.current,
        }),
        // eslint-disable-next-line
        []
    );

    useEffect(() => {
        if (propsError) setErrorText(helperText);
        setError(propsError);
    }, [propsError, helperText]);

    const formLabelProperties = {
        lightColor: lightColor,
        inline: directionProps === 'row',
    };

    return (
        <FormControl
            disabled={disabled}
            error={isError}
            fullWidth={fullWidth}
            required={required}
            className={clsx('field', className)}
            direction={directionProps}
            readOnly={readOnly}
            {...other}
        >
            {label && <FormLabel {...formLabelProperties}>{label}</FormLabel>}
            <Stack direction="column" className="field-input-root">
                <OutlinedInput {...defaultProps} />
                {isError && errorText !== '' && <FormHelperText>{errorText}</FormHelperText>}
            </Stack>
        </FormControl>
    );
});

export default TextField;
