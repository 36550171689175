import React, { useRef } from 'react';
import clsx from 'clsx';
import { Box, Stack, Typography, Switch, Card, Button } from '@Components/';
import { ListInfo, ScrollerArrowButton, Tablenization } from '@Local/Components/';
import { PointsActivityTableMoreLabel } from '@Local/Points/Activity/';
import { usePointsContext } from '@Local/Points/Components/PointsContext';
import { parseDate } from '@Util/moment';
import usePointsActivityApi from '@Apis/usePointsActivityApi';
import useMessageDialog from "@Util/hook/useMessageDialog";

const headerRow = [
    {
        cellKey: 'title',
        headerLabel: '活動標題',
        align: 'center',
    },
    {
        cellKey: 'description',
        headerLabel: '活動說明',
        align: 'center',
        width: 30,
        maxWidth: 30,
    },
    {
        cellKey: 'startDate',
        headerLabel: '活動時間',
        align: 'center',
        formatCell: (value, { endDate }) => {
            return (
                <Stack direction="column">
                    <Typography component="span">{`${parseDate(value)}`}</Typography>
                    <Typography component="span">~</Typography>
                    <Typography component="span">{`${parseDate(endDate)}`}</Typography>
                </Stack>
            );
        },
    },
    {
        cellKey: 'redeemStart',
        headerLabel: '兌換時間',
        align: 'center',
        formatCell: (value, { redeemEnd }) => {
            return (
                <Stack direction="column">
                    <Typography component="span">{`${parseDate(value)}`}</Typography>
                    <Typography component="span">~</Typography>
                    <Typography component="span">{`${parseDate(redeemEnd)}`}</Typography>
                </Stack>
            );
        },
    },
    {
        cellKey: 'isVisible',
        headerLabel: '是否開啟',
        align: 'center',
        formatCell: (value, rowData, { onSwitchChange }) => {
            return <Switch defaultChecked={value} onChange={(_e, checked) => onSwitchChange(rowData, { isVisible: checked }, rowData.activityID)} />;
        },
    },
    {
        cellKey: '',
        headerLabel: '',
        align: 'center',
        formatCell: (_, rowData, { onClick }) => {
            return <PointsActivityTableMoreLabel rowData={rowData} onClick={onClick} />;
        },
    },
];

const PointsActivityTable = (props) => {
    const { sourceData, pageModel, dialogRef, refresh } = usePointsContext();
    const { putPointsActivityOverviewApi, putPointsActivityResetApi } = usePointsActivityApi();
    const scrollerArrowRef = useRef(null);
    const setMessageDialog = useMessageDialog();

    const handleAddPointActivityOnClick = () => {
        dialogRef.current.openProductActivityDialog({}, refresh);
    };

    const handleTableMoreLabelOnClick = (type, targetData) => {
        if (type === 'editPointActivity') {
            dialogRef.current.openProductActivityDialog(targetData, refresh);
        } 
        else if (type === 'resetPointActivityItems') {
            setMessageDialog({
                open: true,
                title: '重置點數活動贈品項目',
                msg: `是否要重置[${targetData.title}]點數活動贈品項目？`,
                onAsyncConfirm: (_, onClose) => doResetActivityApi(targetData.activityID, onClose),
            });
        }
    };

    const handleIsVisibleOnChange = (targetData, changeData, activityID) => {
        let params = JSON.parse(JSON.stringify(targetData));
        Object.assign(params, changeData);
        putEditActivityApi(params, activityID);
    };

    const putEditActivityApi = async (params, activityID) => {
        const resp = await putPointsActivityOverviewApi(params, activityID);
        resp && setTimeout(refresh, 5);
    };
    
    const doResetActivityApi = async (activityID, closeFn) => {
        const resp = await putPointsActivityResetApi({}, activityID);
        resp && setTimeout(refresh, 5);
        closeFn();
    }

    return (
        <Card className="py-2">
            <Card.CardContent>
                <Box className="mb-4 px-3">
                    <Button onClick={handleAddPointActivityOnClick} color="info" variant="contained">
                        新增點數活動
                    </Button>
                    <Stack direction="column">
                        <ListInfo
                            className={clsx('ml-auto', 'mb-2')}
                            pageSize={pageModel.pageSize}
                            pageIndex={pageModel.pageIndex}
                            totalCount={pageModel.totalCount}
                            isCurrentPageFirstIndex
                            isCurrentPageLastIndex
                        />
                        <Box className={'scroll-arrow-container'}>
                            <Tablenization cardProps={{ className: 'theme-gray' }} tableContainerRef={scrollerArrowRef} cellWidth={16} isPagination isFixed>
                                <Tablenization.TableRowHeader headerRow={headerRow} />
                                <Tablenization.TableRowBody
                                    headerRow={headerRow}
                                    rowsData={sourceData}
                                    onClick={handleTableMoreLabelOnClick}
                                    onSwitchChange={handleIsVisibleOnChange}
                                />
                            </Tablenization>
                            <ScrollerArrowButton parentRef={scrollerArrowRef} />
                        </Box>
                    </Stack>
                </Box>
            </Card.CardContent>
        </Card>
    );
};

export default PointsActivityTable;
