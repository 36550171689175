const useSecurityConfirm = () => {
    const checkConfirmPwd = (oriRef, newRef, confRef) => {
        let checked = false;
        const oriPwd = oriRef.current.getResult();
        const newPwd = newRef.current.getResult();
        const confPwd = confRef.current.getResult();
        if (oriPwd === newPwd) {
            oriRef.current.setError(true, '不得與新密碼相同');
        } else if (newPwd !== confPwd) {
            confRef.current.setError(true, '確認密碼與新密碼不相同');
        } else {
            checked = true;
        }

        if (newRef.current.isError()) {
            newRef.current.setError(true, '');
        }
        
        return checked;
    };

    return {
        doConfirmPwd: checkConfirmPwd,
    }
}

export default useSecurityConfirm;