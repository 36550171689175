import React, { useRef } from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import clsx from 'clsx';
import { Button, Form, SecurityTextField, TextField } from '@Components/';
import { GradientColorHeader } from '@Local/Components/Dialog';
import useAuthUserApi from '@Apis/useAuthUserApi';
import { refIsRequiredError } from '@Util/utils';
import useAuthApi from '@Apis/useAuthApi';
import useSecurityConfirm from "@Util/hook/useSecurityConfirm";

const PasswordResetDialog = (props) => {
    const { open = false, onClose } = props;
    const { putAuthUserPasswordApi } = useAuthUserApi();
    const { getLogoutApi } = useAuthApi();
    const oldPasswordRef = useRef();
    const newPasswordRef = useRef();
    const confirmPasswordRef = useRef();
    const { doConfirmPwd } = useSecurityConfirm();

    const putUserPasswordApi = async (params) => {
        let resp = await putAuthUserPasswordApi(params);
        if (resp) {
            setTimeout(() => {
                getLogoutApi();
            }, 5000);

            if (typeof onClose === 'function') onClose();
        }
    };

    const getParams = () => {
        let result = {};

        if (oldPasswordRef.current && oldPasswordRef.current.getResult) {
            Object.assign(result, { oldSecurity: oldPasswordRef.current.getResult() });
        }
        if (newPasswordRef.current && newPasswordRef.current.getResult) {
            Object.assign(result, { security: newPasswordRef.current.getResult() });
        }
        if (confirmPasswordRef.current && confirmPasswordRef.current.getResult) {
            Object.assign(result, { reconfirmSecurity: confirmPasswordRef.current.getResult() });
        }
        return result;
    };

    const handleSubmit = () => {
        const isError = refIsRequiredError(oldPasswordRef, newPasswordRef, confirmPasswordRef);
        const isConfirm = doConfirmPwd(oldPasswordRef, newPasswordRef, confirmPasswordRef);

        if (!isError && isConfirm) {
            const params = getParams();
            putUserPasswordApi(params);
        }
    };

    return (
        <Dialog
            className={clsx('simple-content', 'dialog')}
            PaperProps={{ className: 'wx-65rem', component: Form }}
            open={open}
            onSubmit={handleSubmit}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>變更密碼</GradientColorHeader>
            <DialogContent>
                <Grid spacing={3} container>
                    <Grid xs={12} item>
                        <TextField ref={oldPasswordRef} label={'目前密碼'} type={'password'} required fullWidth />
                    </Grid>
                    <Grid xs={12} item>
                        <SecurityTextField ref={newPasswordRef} label={'新密碼'} required fullWidth/>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField ref={confirmPasswordRef} label={'確認新密碼'} type={'password'} required fullWidth />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" type="submit">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PasswordResetDialog;
